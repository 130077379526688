import * as React from 'react';

import { Box, BoxProps, Spinner, SpinnerProps, Text } from '@chakra-ui/react';

export function FullPageSpinner({
  size,
  color,
  speed,
  label,
  thickness,
  emptyColor,
  placeholder,
  ...rest
}: BoxProps & SpinnerProps & { placeholder?: string }) {
  const spinnerProps = { label, speed, thickness, size, color, emptyColor };
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      {...rest}
    >
      <Spinner {...spinnerProps} color="blue" />
      {placeholder && <Text mt="1rem">{placeholder}</Text>}
    </Box>
  );
}
